export const getComposedHref = <T extends Record<string, string>>(
  href: string,
  queryParams: T
): string => {
  // Preserve any existing query params in href, and add those from queryParams.
  const url = new URL(href)
  const searchParams = new URLSearchParams(url.search)
  Object.entries(queryParams).forEach(([key, value]) => {
    if (value === undefined) {
      return
    }

    searchParams.set(key, value)
  })
  url.search = searchParams.toString()

  return url.toString()
}
