import type { PreselectedServiceValues } from 'lib/url/wills/constants'

export const getQueryParams = ({
  preselectedService,
  partnerCampaignReferral,
  telephoneWillsEnabled,
  code,
}: {
  preselectedService?: Extract<PreselectedServiceValues, 'online' | 'telephone'>
  partnerCampaignReferral: boolean
  telephoneWillsEnabled?: boolean
  code?: string
}): Record<string, string> => ({
  ...(preselectedService && { preselected_service: preselectedService }),
  ...(partnerCampaignReferral && {
    partner_campaign_referral: 'true',
    telephone_wills_enabled: telephoneWillsEnabled ? 'true' : 'false',
  }),
  ...(code && { code }),
})
