import React from 'react'

import { FAREWILL_WILLS_URL } from 'config'
import { filterByKey } from 'lib/object/helpers'
import { getSessionStorage } from 'lib/storage/sessionStorage'
import { trackEvent } from 'lib/tracking'
import { getComposedHref } from 'lib/url/getComposedHref'

import { getQueryParams } from './helpers'

type ProductLinkOptions = {
  preselectedService?: 'online' | 'telephone'
  partnerCampaignReferral: boolean
  telephoneWillsEnabled: boolean
}

type ProductLinkProps = ProductLinkOptions & {
  className: string
  to: string
  children: React.ReactNode
}

const handleClick = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  const target = event.target as HTMLAnchorElement
  trackEvent(`Marketing link to ${target.href}`, 'click')
}

const ProductLink = ({
  className,
  to = '/',
  children,
  preselectedService,
  partnerCampaignReferral = false,
  telephoneWillsEnabled = false,
  ...props
}: ProductLinkProps): React.ReactElement => (
  <a
    className={className}
    href={getComposedHref(
      `${FAREWILL_WILLS_URL}${to}`,
      getQueryParams({
        preselectedService,
        partnerCampaignReferral,
        telephoneWillsEnabled,
        code: getSessionStorage<{ code: string }>('urlParams')?.code,
      })
    )}
    onClick={handleClick}
    // Forward data attributes to underlying DOM element. Used for event
    // tracking (`data-track-event="click"`). See plugins/splitio-plugin/gatsby-browser.tsx
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...filterByKey(props, (key) => key.startsWith('data-'))}
  >
    {children}
  </a>
)

export default ProductLink
